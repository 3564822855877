import colors from '__designkit__/common/colors';
import Fonts from '__designkit__/common/fonts';
import Icon from '__designkit__/icon/Icon';
import toggleLottie from 'assets/lottie/toggle.json';
import JDALink from 'components/JDALink';
import JDSimpleInfoModal from 'components/_v2/_common/modals/JDSimpleInfoModal';
import PartershipListModal from 'components/myPage/MyPagePartershipListModal';
import RoutePaths from 'consts/RoutePaths';
import { ScrollHiddenMixin } from 'consts/_v2/_common/style/mixins';
import { lineClampBox } from 'consts/style/mixins';
import { IInfoCardData, PrivacyContentsEnum } from 'interfaces/_v2/contents/IContents';
import { Space } from 'interfaces/_v2/contents/IContentsRq';
import Lottie from 'lottie-react';
import { inject, observer } from 'mobx-react';
import Login from 'models/Login';
import Contents from 'models/_v2/Contents';
import { injectStore } from 'models/store';
import React, { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import styled, { css } from 'styled-components';
import Swiper from 'swiper';
import JDSwiper, { JDSwiperSlide } from '../_common/JDSwiper';
import MainNoPosition from './MainNoPosition';

const Frame = styled.div`
  width:100%;
`;

const TitleFrame = styled.div`
    display: flex;
    justify-content: space-between;
    margin:40px 16px 16px 16px;

    .title{
        font: ${Fonts.H5_Bold};
        color:${colors.JOBDA_BLACK};
        width: 288px;
        word-break: break-all;
    }
    .more-text{
        display: flex;
        align-items: center;
        font: ${Fonts.B3_Medium};
        color:${colors.JOBDA_BLACK};
    }
`;

export const StyledMainCurationTab = styled.div`
    width:100%;
    overflow: hidden;
    margin-bottom: 16px;
    .curation-frame{
    padding: 0px 16px 0px 16px;
    width: 100%;
    display: inline-flex;
    overflow-x: scroll;
    height: 36px;
    ${ScrollHiddenMixin()}
    
   
}
`;
export const StyledMainCurationChip = styled.div<{selected:boolean}>`
   
        padding: 0 16px 0 16px;
        background-color: ${(props) => (props.selected ? `${colors.JOBDA_WHITE}` : `${colors.WHITE_100}`)} ;
        border: ${(props) => (props.selected ? `1px solid ${colors.JOBDA_BLACK}` : `1px solid ${colors.CG_50}`)} ;
        border-radius: 48px;
        margin-right: 8px;
        white-space: nowrap;
        display: flex;
        align-items: center;
        .icon{
            width:24px;
            height: 24px;
            margin-right:6px;
        }
        .curation-text{
            color:${(props) => (props.selected ? `${colors.JOBDA_BLACK}` : `${colors.CG_80}`)} ;
            font: ${(props) => (props.selected ? css`${Fonts.B3_Bold}` : css`${Fonts.B3_Medium}`)};
        }
    
`;
const MainContentsFrame = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 100vw;
  background: ${colors.WHITE_100};
  margin-bottom: 40px;

  .contents-custom{
    width:240px;
    margin-right: 16px;
    border-radius: 10px;
  }
  
`;

const MainContentsCardFrame = styled.div`
  position:relative;
  width: 240px;
  .img{
    width: 240px;
    height: 180px;
    border-radius: 9.6px;
  }
  .title{
    font: ${Fonts.B3_Medium};
    color:${colors.JOBDA_BLACK};
    text-decoration: underline;
    margin-top: 16px;
  }
  .subtitle{
    font: ${Fonts.B2_Bold};
    color:${colors.JOBDA_BLACK};
    height: 36px;
    margin-top: 12px;
    ${lineClampBox(2)}
  }
  .hash-tag-frame{
    display: flex;
    justify-content: flex-start;
    margin-top: 12px;
    .hash-tag{
        font: ${Fonts.B3_Medium};
        color:${colors.CG_60};
        margin-right: 8px;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        overflow: hidden;
    }
  }
`;
const ModalFrame = styled.div`
  
  background: ${colors.WHITE_100};
  width: 100%;
  height: 100%;

  .icon-frame{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  h1{
    text-align: center;
    margin-bottom:8px;
    font:${Fonts.H4_Bold}
  }
  h2{
    text-align: center;
    margin-bottom:16px;
    font:${Fonts.B2_Medium};
    color:${colors.CG_70};
  }
.toggle-container{
    display: flex;
    width: 100%;
    border:1px solid ${colors.CG_40};
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.05);
    padding: 20px 24px;
    justify-content: space-between;
    align-items: center;    
    border-radius: 8px;
    .title-text{
        font:${Fonts.B3_Medium};
        color:${colors.CG_70};
    }
    .toggle-box{
        display: flex;
        align-items: center;
        .toggle-text{
            font:${Fonts.B2_Bold};
            color:${colors.JOBDA_BLACK};
            margin-right: 12px;
        }
        .toggle-switch{
            
        }
    }

}
  .apply{
    background-color: ${colors.JOBDA_BLACK};
    color:${colors.JOBDA_WHITE};
    font:${Fonts.B1_Bold};
    width: 100%;
    border-radius: 4px;
    padding:14px 0;
    margin-top: 22px;
  }
    
  .list{
    background-color: ${colors.JOBDA_WHITE};
    color:${colors.JOBDA_BLACK};
    border: 1px solid ${colors.JOBDA_BLACK};
    font:${Fonts.B1_Bold};
    width: 264px;
    border-radius: 4px;
    padding:14px 0;
    margin-top: 16px;
  }
`;
interface IMainContents {
  contents?: Contents;
  preparation?:boolean;
  login?:Login;
}

interface MainContentsCardProps {
  item: IInfoCardData;
  setIsMatchingModal: (value: boolean) => void;
  setIsPartnershipModal: (value: boolean) => void;
  isPartnerAuthority?:boolean
  isMatchingUser?:boolean
}

const MainContentsCard:FC<MainContentsCardProps> = ({ item, setIsMatchingModal, setIsPartnershipModal, isPartnerAuthority, isMatchingUser }) => {
  if (item.privacy === PrivacyContentsEnum.MATCHING_USER || item.privacy === PrivacyContentsEnum.PARTNERSHIP) {
    if (!isPartnerAuthority && item.privacy === PrivacyContentsEnum.PARTNERSHIP) {
      return (
        <MainContentsCardFrame onClick={() => setIsPartnershipModal(true)}>
          <img src={`${item.imageSrc}?w=480&h=360`} className='img' alt='img' />
          <div className='title'>{item.category}</div>
          <div className='subtitle'>{item.title}</div>
          <div className='hash-tag-frame'>
            <div className='hash-tag'>{item.subTitle}</div>
          </div>
        </MainContentsCardFrame>
      );
    } if (!isMatchingUser && item.privacy === PrivacyContentsEnum.MATCHING_USER) {
      return (
        <MainContentsCardFrame onClick={() => setIsMatchingModal(true)}>
          <img src={`${item.imageSrc}?w=480&h=360`} className='img' alt='img' />
          <div className='title'>{item.category}</div>
          <div className='subtitle'>{item.title}</div>
          <div className='hash-tag-frame'>
            <div className='hash-tag'>{item.subTitle}</div>
          </div>
        </MainContentsCardFrame>
      );
    }
  }
  return (
    <JDALink to={item.type === 'NEW_PAGE' ? `${item.link}` : RoutePaths.info_detail(item.id)}>
      <MainContentsCardFrame>
        <img src={`${item.imageSrc}?w=480&h=360`} className='img' alt='img' />
        <div className='title'>{item.category}</div>
        <div className='subtitle'>{item.title}</div>
        <div className='hash-tag-frame'>
          <div className='hash-tag'>{item.subTitle}</div>
        </div>
      </MainContentsCardFrame>
    </JDALink>
  );
};

const MainContents:FC<IMainContents> = ({ contents = new Contents(), preparation = false, login = new Login() }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [swiperObj, setSwiperObj] = useState<Swiper | null>(null);
  const [selectedCategoryNumber, setSelectedCategoryNumber] = useState<number>(0);
  const [isMatchingModal, setIsMatchingModal] = useState<boolean>(false);
  const [isPartnershipModal, setIsPartnershipModal] = useState<boolean>(false);
  const [openList, setOpenList] = useState<boolean>(false);
  const isPartnerAuthority = login.userInfo?.authorities.includes('PARTNERSHIP');
  const isMatchingUser = login.userInfo?.isMatchingUser;
  useEffect(() => {
    (async () => {
      setLoading(true);
      await contents.getCategoryList(Space.MAIN);
      setSelectedCategoryNumber(contents.selectedCategory);
      setLoading(false);
    })();
  }, [contents]);

  useEffect(() => {
    if (swiperObj && contents.contents) {
      swiperObj.slideTo(0);
    }
  }, [swiperObj, contents.contents]);

  const selectedCategoryRef = useRef<HTMLDivElement | null>(null);
  const curationFrameRef = useRef<HTMLDivElement | null>(null);
  useLayoutEffect(() => {
    if (selectedCategoryRef.current && curationFrameRef.current) {
      const frame = curationFrameRef.current;
      const chip = selectedCategoryRef.current;
      const chipWidth = chip.offsetWidth;
      const chipLeft = chip.offsetLeft;
      const scrollLeft = chipLeft - (frame.offsetWidth / 2) + (chipWidth / 2) + 100;
      curationFrameRef.current.scrollTo({
        left: scrollLeft,
        behavior: 'smooth',
      });
    }
  }, [contents.selectedCategory, selectedCategoryRef, selectedCategoryNumber]);

  const handleListClick = async () => {
    setIsPartnershipModal(false);
    if (login.userInfo === null) {
      history.push(RoutePaths.login);
    } else {
      setOpenList(true);
    }
  };

  const handleTotalClick = () => {
    contents.setSelectedCategory(-1);
    history.push(RoutePaths.info);
  };
  return (
    <Frame>
      <TitleFrame>
        <div className='title'>{preparation ? `취업 꿀팁은 여기 몽땅!` : `취업 콘텐츠`}</div>
        <div onClick={handleTotalClick} role='button' className='more-text'>전체보기<Icon name='arrow-right' size={24} color={colors.JOBDA_BLACK} /></div>

      </TitleFrame>
      <StyledMainCurationTab>
        <div className='curation-frame' ref={curationFrameRef}>
          {contents.categories.length > 0 && contents.categories.map((item) => (
            <StyledMainCurationChip
              key={item.id}
              ref={selectedCategoryNumber === item.id ? selectedCategoryRef : null}
              selected={selectedCategoryNumber === item.id}
              onClick={() => {
                contents.setSelectedCategory(item.id);
                setSelectedCategoryNumber(item.id);
              }}
            >
              <div className='curation-text'>
                {item.name}
              </div>
            </StyledMainCurationChip>
          ))}
        </div>
      </StyledMainCurationTab>
      <MainContentsFrame>
        <JDSwiper
          slidesPerView='auto'
          slidesOffsetBefore={16}
          slidesOffsetAfter={16}
          navigation
          onSwiper={(e) => setSwiperObj(e)}
        >
          {
            contents.contents.length > 0 ? contents.contents.map((item) => (
              <JDSwiperSlide key={item.id} className='contents-custom'>
                {MainContentsCard({ item, setIsMatchingModal, setIsPartnershipModal, isPartnerAuthority, isMatchingUser })}
              </JDSwiperSlide>
            )) : !loading ? <MainNoPosition contents /> : <></>
          }
        </JDSwiper>
      </MainContentsFrame>
      <JDSimpleInfoModal
        onClickClose={() => {
          setIsMatchingModal(false);
        }}
        isOpen={isMatchingModal}
        isDimClickClose
        btnText='잡다매칭 참여하러 가기'
        handleClick={() => history.push(RoutePaths.match_apply)}
        dimmed
        descriptionsTag={(
          <ModalFrame>
            <h1>잡다매칭에 참여 중인 회원에게만 <br /> 공개된 콘텐츠입니다.
            </h1>
            <h2>{`매칭 설정 > 제안 받기를 켜주세요.`}</h2>
            <div className='toggle-container'>
              <div className='title-text'>매칭 설정</div>
              <div className='toggle-box'>
                <div className='toggle-text'>제안 받기</div>
                <Lottie animationData={toggleLottie} />

              </div>
            </div>
          </ModalFrame>
  )}
      />
      <JDSimpleInfoModal
        onClickClose={() => {
          setIsPartnershipModal(false);
        }}
        isOpen={isPartnershipModal}
        isDimClickClose
        dimmed
        isButton={false}
        descriptionsTag={(
          <ModalFrame>
            <div>
              <h1>제휴서비스 인증이 필요해요
              </h1>
              <button className='apply' onClick={() => history.push(RoutePaths.mypage_partnership)}>제휴서비스 신청하기</button>
              <button
                className='list'
                onClick={handleListClick}
              >제휴리스트 확인하기
              </button>
            </div>

          </ModalFrame>
  )}
      />
      {
        login.userInfo && (
        <PartershipListModal
          isOpen={openList}
          onClose={() => setOpenList(false)}
        />
        )
      }
    </Frame>
  );
};

export default inject(injectStore.contents, injectStore.login)(observer(MainContents));
