import { RecruitmentType } from 'consts/_v2/position/PositionType';
import { MatchAccAnalysisType } from 'consts/MatchAnalysisType';
import { AccGradeResultType } from 'consts/_v2/acc/AccApplyType';
import { JobPostingDto } from '../positionList/IPositionList';

export enum AccResultType {
  ENERGIZER='ENERGIZER',
  SOCIALIZER = 'SOCIALIZER',
  SUPPORTER = 'SUPPORTER',
  CHALLENGER = 'CHALLENGER',
  PASSIONIST = 'PASSIONIST',
  FAIR_GAMER = 'FAIR_GAMER',
  TALENTED = 'TALENTED',
  SMARTS = 'SMARTS',
  GROWTH_PERSON = 'GROWTH_PERSON',
  CAREFUL = 'CAREFUL',
  SOLVER = 'SOLVER',
  HARMONIST = 'HARMONIST'
}

export enum AccResultTypeText {
  ENERGIZER='씩씩한 자신감형',
  SOCIALIZER = '긍정적인 사교형',
  SUPPORTER = '겸손한 지지형',
  CHALLENGER = '도전적인 전략가형',
  PASSIONIST = '성취추구 열정형',
  FAIR_GAMER = '공정한 승부사형',
  TALENTED = '집요한 재능형',
  SMARTS = '당당한 스마트형',
  GROWTH_PERSON = '따뜻한 성장지향형',
  CAREFUL = '꾸준한 신중형',
  SOLVER = '눈치빠른 해결형',
  HARMONIST = '공감하는 화합형'
}

export enum AccProgressType {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  CONNECT_OVER = 'CONNECT_OVER'
}
export interface IAccApplyDeleteRq{
  companySn:boolean
  applySns:number[];
}
export interface IAccResult {
  url: string
  chanceCount: number
  resultRecords: IAccResultRecord[]
  applyStatus: IAccApplyStatus
  profileResultType: AccResultType
}

export interface IAccResultRecord {
  sn: number
  code: string
  name: string
  externalYn: boolean
  version: string
  analysisType: MatchAccAnalysisType
  resultType: AccGradeResultType
  applicationDate: string
  thumbnailUrl: string
}

export interface IAccApplyStatus {
  code: string
  version: string
  progressType: string
}

// 역량검사 응시자 수 조회
export interface IAccCenterApplyCount {
  today: number
  total: number
}

// 최근 응시내역 목록
export interface IAccCenterApplyRecent {
  sn: number
  code: string
  lastModifiedDateTime: string
  thumbnailUrl: string
  title: string
  completed: number
  total: number
  visibleYn:boolean
}

export interface IAccCenterCompanyApplyRecent {
  sn: number
  code: string
  lastModifiedDateTime: string
  thumbnailUrl: string
  title: string
  completed: number
  total: number
  submitDeadline:string | null;
  submitYn:boolean;
  saveYn:boolean;
  visibleYn:boolean
}

export interface IAccCenterPost {
  sn: number
  categoryName: string
  categoryIconType: string
  privacy: string
  type: string
  title: string
  label: string
  thumbnailUrl: string
}

export interface IAccCenterPostRs{
  posts:IAccCenterPost[];
  categorySn:number;
}

export interface IAccCenterJobPosting {
  sn: number
  companyName: string
  title: string
  closingDateTime: string
  logoImageUrl: string
}

export enum AccCenterPositionType {
  ACC_RESULT_TYPE = 'ACC_RESULT_TYPE',
  ACC_RECOMMEND_JOB_GROUP = 'ACC_RECOMMEND_JOB_GROUP',
}

export enum AccCenterPositionTypeText {
  ACC_RESULT_TYPE = '유형이 많이 본 포지션이에요',
  ACC_RECOMMEND_JOB_GROUP = '추천 직군과 딱 맞는 포지션이에요',
}

export interface IAccCenterPositionRs {
  positions: IAccCenterPosition[];
  type: any;
}

export interface IAccCenterPosition {
  sn: number
  title: string
  companyName: string
  locationCode: number
  logoImageUrl: string
  teamImageUrl: string
  recruitmentType: RecruitmentType
  jobTitleName: string
  bookmarkYn: boolean
  matchingYn: boolean
  minSalary: number
  maxSalary: number
  closingDateTime: string
  jobPosting:JobPostingDto;
}
