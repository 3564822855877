/* eslint-disable brace-style */
import AppInduceActionModal from 'components/_v2/_common/modals/AppInduceActionModal';
import AppInduceModal from 'components/_v2/_common/modals/AppInduceModal';
import PasswordChangeModal from 'components/_v2/_common/modals/PasswordChangeModal';
import PopupRouter from 'components/_v2/_common/modals/PopupRouter';
import ResumeNudgeModal from 'components/_v2/_common/modals/ResumeNudgeModal';
import ProfileResumeSavedModal from 'components/_v2/profile/ProfileResumeSavedModal';
import ProfileResumeLoadModal from 'components/_v2/profile/loadResume/ProfileResumeLoadModal';
import MatchServiceMainInduceModal from 'components/_v2/profile/match/MatchServiceMainInduceModal';
import RoutePaths from 'consts/RoutePaths';
import { inject, observer } from 'mobx-react';
import Context from 'models/Context';
import Login from 'models/Login';
import { injectStore } from 'models/store';
import { useInsightResumeAutoSave, useInsightResumeUnread } from 'query/resume/useResumeQuery';
import { FC, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import MobileStore from 'store/mobileStore';
import RenderCheckUtil from 'utils/RenderCheckUtil';
import TodayRenderCheckUtil from 'utils/TodayRenderCheckUtil';
import ServiceErrorModal from './ServiceErrorModal';

interface IOptionModalController{
    login?:Login
    context?:Context
}
const OptionModalController: FC<IOptionModalController> = ({ login = new Login(), context = new Context() }) => {
  const [isPriorityVisible, setIsPriorityVisible] = useState(true);
  const [passwordChangeModal, setPasswordChangeModal] = useState<boolean>(false);
  const { data: isResumeExisted } = useInsightResumeUnread(!!login.userInfo);
  const { data: insightResumeAutoSaveData } = useInsightResumeAutoSave(!!login.userInfo);
  const [isResumeRender, setIsResumeRender] = useState<boolean>(false);
  const [isOpenResumeModal, setIsOpenResumeModal] = useState<boolean>(false);
  const [isOpenResumeNudgeModal, setIsOpenResumeNudgeModal] = useState<boolean>(false);
  const [isRequiredExposure, setIsRequiredExposure] = useState<boolean>(true);
  const [isOpenAppInduceModal, setIsOpenAppInduceModal] = useState<boolean>(false);
  const [isProfileResumeSavedModal, setIsProfileResumeSavedModal] = useState<boolean>(false);
  const [isOpenServiceErrorModal, setIsOpenServiceErrorModal] = useState<boolean>(false);

  const { pathname } = useLocation();
  useEffect(() => {
    const init = async () => {
      setIsResumeRender(!(await RenderCheckUtil.getRenderCheck('resume-induce')));
      const check = await TodayRenderCheckUtil.getTodayRenderCheck('appInduce');
      const resumeCheck = await TodayRenderCheckUtil.getTodayRenderCheck('resume-check');
      // const serviceErrorCheck = await TodayRenderCheckUtil.getTodayRenderCheck('service-check');
      // if (!serviceErrorCheck) {
      //   setIsOpenServiceErrorModal(true);
      //   return;
      // }
      if (login.userInfo?.authorities.includes('USER_15_TEEN')) {
        if (window.location.pathname !== RoutePaths.update_birthdate) {
          setIsRequiredExposure(true);
          window.location.href = RoutePaths.update_birthdate;
        }
        return;
      }
      if (login.userInfo?.authorities.includes('LONG_TERM_INACTIVE_VERIFICATION_REQUIRED')) {
        if (window.location.pathname !== RoutePaths.update_account) {
          setIsRequiredExposure(true);
          window.location.href = RoutePaths.update_account;
        }
        return;
      }
      if (login.userInfo?.authorities.includes('PASSWORD_CHANGE_REQUIRED')) {
        setIsRequiredExposure(true);
        setPasswordChangeModal(true);
      } else
      if (insightResumeAutoSaveData && !resumeCheck && pathname === RoutePaths.profile && login.userInfo) {
        if (insightResumeAutoSaveData.autoSaved) {
          setIsRequiredExposure(true);
          context.isGlobalModalOpen = true;
          setIsProfileResumeSavedModal(true);
        }
      } else
      if (!passwordChangeModal && isResumeExisted && isResumeRender && login.userInfo && (pathname === RoutePaths.profile || pathname === RoutePaths.home || pathname === RoutePaths.mypage_apply)) {
        setIsRequiredExposure(true);
        context.isGlobalModalOpen = true;
        setIsOpenResumeNudgeModal(true);
      } else if (!passwordChangeModal && !isResumeRender && !isOpenResumeNudgeModal && pathname !== RoutePaths.position_ats_preview && pathname !== RoutePaths.roulette && !MobileStore.isMobile && !check) {
        setIsRequiredExposure(true);
        context.isGlobalModalOpen = true;
        setIsOpenAppInduceModal(true);
      } else
      {
        context.isGlobalModalOpen = false;
        setIsRequiredExposure(false);
        setIsPriorityVisible(false);
      }
    };
    init();
  }, [login.userInfo, isResumeExisted, pathname, insightResumeAutoSaveData]);
  return (
    <>
      <PasswordChangeModal isOpen={passwordChangeModal} handleClose={() => setPasswordChangeModal(false)} />
      { isOpenResumeModal && (
      <ProfileResumeLoadModal
        isByNudgeModal
        onClickClose={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeModal(false);
        }}
      />
      ) }
      {isOpenAppInduceModal && !isOpenResumeNudgeModal && <AppInduceActionModal isOpenAppInduceActionModal={isOpenAppInduceModal} setIsOpenAppInduceActionModal={setIsOpenAppInduceModal} />}
      { isProfileResumeSavedModal && <ProfileResumeSavedModal onClickClose={() => setIsProfileResumeSavedModal(false)} /> }
      {/* {isOpenServiceErrorModal && <ServiceErrorModal isOpenServiceModal={isOpenServiceErrorModal} setIsOpenServiceModal={setIsOpenServiceErrorModal} />} */}
      { isOpenResumeNudgeModal && (
      <ResumeNudgeModal
        matchDashboard
        onClickClose={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeNudgeModal(false);
        }}
        afterFailLogic={async () => {
          context.isGlobalModalOpen = false;
          setIsResumeRender(false);
        }}
        afterLogic={() => {
          context.isGlobalModalOpen = false;
          setIsOpenResumeModal(true);
        }}
      />
      ) }

      {
        !isRequiredExposure && (
          <>
            <MatchServiceMainInduceModal isRequiredExposure={isRequiredExposure} setIsPriorityVisible={setIsPriorityVisible} />
            {
              !isPriorityVisible && <PopupRouter isRequiredExposure={isRequiredExposure} />
            }

          </>
        )
      }

    </>
  );
};
export default inject(injectStore.login, injectStore.context)(observer(OptionModalController));
